<template>
  <div>
    <div class="silder-button" v-if="showButton" @click="answer">
      <img src="@/assets/images/examination.png" class="icon-img" alt="" srcset="" />
      <div class="tips-main">
        <div class="tips-main-title">AI助手</div>
        <div>查询学习政策文件找我</div>
      </div>
      <!-- <van-button type="primary" @click="answer">大模型问答</van-button> -->
    </div>
    <van-action-sheet v-model="showLargeModule" title="燕溪大模型">
      <AnswerPage class="large-content-main" :question="largerModuleQuestion" />
    </van-action-sheet>
  </div>
</template>
<script>
export default {
  name: 'PopLargerData',
  components: {
    AnswerPage: () => import('@/components/AnswerPage/index.vue'), // 大模型
  },
  props: {},
  data() {
    return {
      showButton: false,
      showLargeModule: false,
      largerModuleQuestion: '',
      cacheQuestion: '',
    }
  },
  computed: {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log('watch---->showButton', val)
        if (['/practice'].includes(val['path']) && val['query']['showButton']) {
          this.showButton = true
        } else {
          this.showButton = false
        }
      },
      immediate: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.$EventBus.$on('questionMsg', (msg) => {
      //   console.log('this.$EventBus.$on--->', msg)
      this.cacheQuestion = msg
      // A发送来的消息
      //   this.msg = msg
    })
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    answer() {
      console.log(this.$route.path)
      //   console.log(this.questionList[this.$refs.mySwiper.$swiper.activeIndex]['question'])
      //   this.largerModuleQuestion = this.questionList[this.$refs.mySwiper.$swiper.activeIndex]['question']
      this.largerModuleQuestion = this.cacheQuestion
      this.showLargeModule = true

      // this.showLargeModule = true
      // this.$nextTick(() => {})
      //this.$refs.mySwiper.$swiper.activeIndex
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.silder-button {
  position: fixed;
  right: 0px;
  bottom: 10vh;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  .icon-img {
    width: 46px;
    margin-right: -25px;
    z-index: 10;
  }
  .tips-main {
    background-color: #dcdfe6;
    z-index: 9;
    padding-left: 25px;
    height: 38px;
    // border-top: 1px solid #f56c6c;
    // border-bottom: 1px solid #f56c6c;

    .tips-main-title {
      text-align: center;
      font-size: 15px;
      color: #f56c6c;
    }
  }
}
.large-content-main {
  height: 70vh;
}
</style>
