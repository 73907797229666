var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showButton
        ? _c(
            "div",
            { staticClass: "silder-button", on: { click: _vm.answer } },
            [
              _c("img", {
                staticClass: "icon-img",
                attrs: {
                  src: require("@/assets/images/examination.png"),
                  alt: "",
                  srcset: "",
                },
              }),
              _vm._m(0),
            ]
          )
        : _vm._e(),
      _c(
        "van-action-sheet",
        {
          attrs: { title: "燕溪大模型" },
          model: {
            value: _vm.showLargeModule,
            callback: function ($$v) {
              _vm.showLargeModule = $$v
            },
            expression: "showLargeModule",
          },
        },
        [
          _c("AnswerPage", {
            staticClass: "large-content-main",
            attrs: { question: _vm.largerModuleQuestion },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips-main" }, [
      _c("div", { staticClass: "tips-main-title" }, [_vm._v("AI助手")]),
      _c("div", [_vm._v("查询学习政策文件找我")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }